/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useCallback, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  configActions,
  dashboardActions,
  discoveryActions,
  propertiesActions,
  propertyActivityActions,
  territoriesActions,
  userActions,
} from '../../../../ducks/actions'
import { DashboardIndicators } from '../../../../ducks/dashboard/types'
import {
  getConfigDashboardDateRange,
  getConfigValue,
  getDashboardData,
  getDashboardIndicators,
  getProperties,
  getPropertyActivity,
  getUser,
} from '../../../../ducks/selectors'
import {
  formatDateToUnixTimestamp,
  getMobileOperatingSystem,
} from '../../../../helpers'
import { useIsMdScreen, useIsSmScreen, useIsXsScreen } from '../../../../hooks'
import { DesktopPage, PrivateLoader } from '../../../templates'
import { Box, Button, Divider, Grid, Tab, Tabs, Typography } from '../../../UI'
import { Range } from '../../../UI/CustomUI/atoms/DatePicker/types'
import useStyles from './styles'
import FeedbackModal from './Modals/FeedbackModal'
import { Item, Property, REFERRAL_TYPE } from 'ducks/types'
import DashboardValueComponent from 'components/UI/CustomUI/atoms/DashboardValueComponent'
import AddIcon from '@material-ui/icons/Add'
import HomeCard from './HomeCard'
import AddHomeCardModal from './Modals/AddHomeCardModal'
import { GroupedOrderType } from './Modals/PurchaseModal/types'
import PurchaseModal from './Modals/PurchaseModal'
import { DashboardContext } from './context.provider'
import { IDashboardContext } from './context'
import {
  bosscatLogo,
  construction,
  hardwareConstruction,
  homeBase,
  homeBaseWhite,
  logoSmall,
} from 'assets/index'
import {
  Assignment,
  Inbox,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@material-ui/icons'
import EstimateJobsModal from './Modals/EstimateJobsModal'
import { isMobile } from 'react-device-detect'
import { Link } from 'react-router-dom'

import { icons } from 'assets'
import DuplicatedErrorModal from './Modals/DuplicatedErrorModal'
import PropertyDisclaimer from 'components/UI/CustomUI/atoms/PropertyDisclaimer'
import HolidayBanner from 'components/UI/CustomUI/organisms/HolidayBanner'

const initialIndicators = {
  estimates: 0,
  activeOrders: 0,
  completedJobs: 0,
  completed: 0,
  estimatesCount: 0,
  jobsCount: 0,
}

const Dashboard: FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [xsScreen] = useIsXsScreen()
  const [mdScreen] = useIsMdScreen()
  const [smScreen] = useIsSmScreen()

  const [currentTab, setCurrentTab] = useState<number>(0)

  const isSmallScreen = xsScreen || smScreen || mdScreen

  const initialRange = useSelector(getConfigDashboardDateRange())
  const dashboardData = useSelector(getDashboardData())
  const dashboardIndicators = useSelector(
    getDashboardIndicators(),
    shallowEqual
  )
  const propertiesActivity = useSelector(getPropertyActivity())

  const user = useSelector(getUser)
  const properties = useSelector(getProperties())

  const initialValues = [
    {
      label: 'Properties',
      labelColor: 'var(--bosscat-green-700)',
      value: propertiesActivity.totalProperties || 0,
      showArrow: false,
      clickable: false,
    },
    {
      label: 'Active Estimates',
      labelColor: 'var(--bosscat-blue-600)',
      value: propertiesActivity.totalActiveEstimates,
      showArrow: true,
      clickable: true,
    },
    {
      label: 'Active Jobs',
      labelColor: 'var(--text-text-info)',
      value: propertiesActivity.totalActiveJobs,
      showArrow: true,
      clickable: true,
    },
    {
      label: 'Repairs Needed',
      labelColor: 'var(--handyman-green-600)',
      value: propertiesActivity.totalDueForReplacement,
      showArrow: false,
      clickable: false,
    },
    // {
    //   label: 'maintenance',
    //   labelColor: 'var(--hardware-haze-700)',
    //   value: propertiesActivity.totalDueForMaintenance,
    //   showArrow: false,
    //   clickable: false,
    // },
    // {
    //   label: 'green rebates',
    //   labelColor: 'var(--text-text-success)',
    //   value: propertiesActivity,
    // },
  ]

  const initialGroupedOrders: GroupedOrderType[] = [
    {
      address: '123 main street, Orlando, FL. 32818',
      items: [
        {
          itemId: '1',
          title: 'GARAGE DOOR',
        },
        {
          itemId: '2',
          title: 'WATER HEATER',
        },
        {
          itemId: '3',
          title: 'DRYER',
        },
      ] as Item[],
    },
    {
      address: '456 main street, Orlando, FL. 32818',
      items: [
        {
          itemId: '4',
          title: 'GARAGE DOOR',
        },
        {
          itemId: '5',
          title: 'roof shingle',
        },
      ] as Item[],
    },
  ]

  const [pageLoading, setPageLoading] = useState(!initialRange)
  const [activityLoading, setActivityLoading] = useState(true)
  const [listLoading, setListLoading] = useState(false)
  const [dateRange /* , setDateRange */] = useState<Range>(initialRange)
  const [addModalOpen, setAddModalOpen] = useState<boolean>(false)
  const [purchaseModalOpen, setPurchaseModalOpen] = useState<boolean>(false)
  const [purchaseModalTitle, setPurchaseModalTitle] = useState<string>('')
  const [cart, setCart] = useState<string[]>([])
  const [estimateJobOpen, setEstimateJobOpen] = useState<boolean>(false)
  const [isEstimateModal, setIsEstimateModal] = useState<boolean>(false)
  const [showValues, setShowValuse] = useState<boolean>(!isSmallScreen)
  const [showDuplicatedModal, setShowDuplicatedModal] = useState<boolean>(false)
  const [groupedOrders, setGroupedOrders] =
    useState<GroupedOrderType[]>(initialGroupedOrders)
  const [purchaseModalTitleColor, setPurchaseModalTitleColor] =
    useState<string>('')
  const [indicators, setIndicators] =
    useState<DashboardIndicators>(initialIndicators)
  const [propertyLoading, setPropertyLoading] = useState<boolean>(false)

  //const showWelcomeEstimate = useSelector(getNewEstimateValue('showWelcome'))
  //const [openModal, setOpenModal] = useState(showWelcomeEstimate)

  const showReferralModal = useSelector(getConfigValue('showReferralModal'))

  const initialState: IDashboardContext = {
    addModalOpen,
    groupedOrders,
    purchaseModalTitle,
    purchaseModalTitleColor,
    purchaseModalOpen,
    cart,
    setAddModalOpen,
    setGroupedOrders,
    setPurchaseModalTitleColor,
    setPurchaseModalOpen,
    setPurchaseModalTitle,
    setCart,
  }

  useEffect(() => {
    setPageLoading(true)
    setActivityLoading(true)

    dispatch(
      propertyActivityActions.fetchPropertiesActivity({}, () => {
        setActivityLoading(false)
      })
    )
  }, [])

  useEffect(() => {
    setPropertyLoading(true)
    dispatch(
      propertiesActions.fetchProperties({ archived: currentTab === 1 }, () => {
        setPropertyLoading(false)
      })
    )
  }, [currentTab])

  useEffect(() => {
    setShowValuse(!isSmallScreen)
  }, [isSmallScreen])

  const fetchDashboardData = useCallback(() => {
    setListLoading(true)

    dispatch(userActions.fetchCurrentUser(() => { }))

    dispatch(
      dashboardActions.fetchDashboardData(
        dateRange.startDate && dateRange.endDate
          ? {
            startDate: formatDateToUnixTimestamp(dateRange.startDate),
            endDate: formatDateToUnixTimestamp(dateRange.endDate),
          }
          : {},
        (_succ: boolean) => {
          setPageLoading(false)
          setListLoading(false)
        }
      )
    )
  }, [dateRange, dispatch])

  const handleSendFeedback = (
    option: REFERRAL_TYPE | string,
    details: string
  ) => {
    // setShowFeedbackModal(false)
    dispatch(
      configActions.setConfigValue({ type: 'showReferralModal', value: false })
    )
    dispatch(
      discoveryActions.sendFeedback({
        resourceId: user.id,
        resourceType: 'CLIENT',
        source: option,
        details,
      })
    )
  }

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setCurrentTab(newValue)
  }

  useEffect(() => {
    fetchDashboardData()
    dispatch(
      configActions.setConfigValue({
        type: 'dashboardDateRange',
        value: dateRange,
      })
    )
  }, [dateRange, dispatch, fetchDashboardData])

  useEffect(() => {
    setIndicators(listLoading ? initialIndicators : dashboardIndicators)
  }, [listLoading, dashboardData])

  /* const handleSelect = (rangeSelected: Range) => {
    setDateRange(rangeSelected)
  } */

  const handleAddProperty = () => {
    setAddModalOpen(true)
  }

  const getModalTitleIcon = (title: string) => {
    if (title === 'Active Estimates') {
      return <Assignment style={{ width: '20px', height: '20px' }} />
    } else if (title === 'Active Jobs') {
      return <Assignment style={{ width: '20px', height: '20px' }} />
    }
  }

  const handlePurchaseClick = (data: any) => {
    if (
      data.label === 'maintenance' ||
      data.label === 'repairs' ||
      data.label === 'properties'
    )
      return

    if (data.label === 'Active Estimates') {
      setIsEstimateModal(true)
      setEstimateJobOpen(true)
    } else if (data.label === 'Active Jobs') {
      setIsEstimateModal(false)
      setEstimateJobOpen(true)
    }

    setPurchaseModalTitle(data.label)
    setPurchaseModalTitleColor(data.labelColor)
  }

  const handleToggleValues = () => {
    setShowValuse(!showValues)
  }

  const userOS = getMobileOperatingSystem()

  return (
    <DashboardContext.Provider value={initialState}>
      <PrivateLoader loading={pageLoading || activityLoading}>
        <Grid
          container
          direction="column"
          style={{ gap: '16px', overflow: 'overlay', position: 'relative' }}
        >
          {isMobile && (
            <Box className={classes.downloadAppButtonContainer}>
              <Box className={classes.downloadAppButton}>
                <img src={logoSmall} alt="logo" />
                <a
                  href={
                    userOS === 'iOS'
                      ? 'https://apps.apple.com/us/app/bosscat/id1671886793'
                      : 'https://play.google.com/store/apps/details?id=com.punchlistusa.robinson&pli=1'
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button endIcon={<icons.ArrowForward />}>
                    Download BOSSCAT App
                  </Button>
                </a>
              </Box>
            </Box>
          )}
          {!isSmallScreen && (
            <Grid container item>
              <Box className={classes.portfolioView}>
                <img
                  src={homeBaseWhite}
                  alt="homebbase"
                  className={classes.homeBaseLogo}
                />
                <Typography className={classes.active}>
                  PORTFOLIO VIEW
                </Typography>
              </Box>
            </Grid>
          )}
          <Grid
            item
            container
            className={classes.container}
            style={{
              marginTop: '64px',
              gap: '24px',
              position: 'absolute',
              height: 'calc(100% - 64px)',
              flexFlow: 'column',
            }}
            xs
          >
            <HolidayBanner />
            <Grid
              container
              item
              className={classes.root}
              style={{ paddingTop: '0px' }}
              spacing={3}
            >
              <Grid
                container
                item
                xs={12}
                className={classes.info}
                style={{ gap: isSmallScreen ? '2px' : '16px' }}
              >
                {isSmallScreen && (
                  <Grid item xs={12}>
                    <Button
                      className={classes.primaryDarkButton}
                      fullWidth
                      onClick={handleToggleValues}
                    >
                      <Typography
                        className={classes.propertyTitle}
                        style={{
                          color: 'white',
                          width: '100%',
                          position: 'relative',
                        }}
                      >
                        PORTFOLIO VIEW
                        {showValues ? (
                          <KeyboardArrowUp
                            className={classes.endIconAbsolute}
                          />
                        ) : (
                          <KeyboardArrowDown
                            className={classes.endIconAbsolute}
                          />
                        )}
                      </Typography>
                    </Button>
                  </Grid>
                )}
                {showValues &&
                  initialValues.map(
                    ({ label, labelColor, value, showArrow, clickable }) => (
                      <Grid item xs={isSmallScreen ? 12 : 'auto'} md>
                        <DashboardValueComponent
                          label={label}
                          labelColor={labelColor}
                          value={value}
                          showArrow={showArrow}
                          clickable={clickable}
                          onClick={handlePurchaseClick}
                        />
                      </Grid>
                    )
                  )}
              </Grid>
            </Grid>
            {/* <Grid item style={{ height: 'fit-content' }}>
              <Divider orientation="horizontal" style={{ width: '100%' }} />
            </Grid> */}
            <Grid item container style={{ gap: '16px', alignItems: 'center' }}>
              <Button
                variant="outlined"
                color="primary"
                startIcon={
                  <AddIcon
                    style={{ width: '16px', height: '16px' }}
                    color="inherit"
                  />
                }
                onClick={handleAddProperty}
                style={{
                  width: isSmallScreen ? '100%' : 'fit-content', height: '46px',
                  backgroundColor: 'var(--bosscat-blue-900)',
                  color: 'white',
                  border: '2px solid var(--bosscat-grey-400)',
                  borderRadius: '8px',
                  boxShadow: '0px 0px 1px 0px rgba(9, 25, 49, 0.31), 0px 1px 1px 0px rgba(9, 25, 49, 0.24)'
                }}
              >
                <Typography className={classes.addPropertyText} style={{ textTransform: 'none', color: 'white', fontWeight: 500 }}>
                  Add Property
                </Typography>
              </Button>
              <Tabs
                variant="scrollable"
                scrollButtons="auto"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: 'var(--bosscat-blue-900)',
                    zIndex: '-1',
                    height: '100%',
                    borderRadius: '8px',
                  },
                }}
                value={currentTab}
                onChange={handleTabChange}
                className={classes.Tabs}
                style={{ width: 'fit-content', padding: 0 }}
                TabScrollButtonProps={{ style: { height: '44px' } }}
              >
                <Tab
                  className={classes.Tab}
                  style={{ width: 'fit-content', margin: 0 }}
                  label="Active Properties"
                />
                <Tab
                  className={classes.Tab}
                  style={{ width: 'fit-content', margin: 0 }}
                  label={
                    <Box
                      display="flex"
                      alignItems="center"
                      style={{ gap: '10px' }}
                    >
                      Archived Properties
                    </Box>
                  }
                />
              </Tabs>
            </Grid>
            <Grid item container xs>
              {!propertyLoading ? (
                <Grid
                  item
                  container
                  spacing={2}
                  style={{ height: 'fit-content' }}
                >
                  {properties.map((property: Property, index: number) => (
                    <Grid item xl={4} lg={6} md={12} style={{ width: '100%' }}>
                      <HomeCard property={property} index={index} />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Grid item container justifyContent="center">
                  <Grid item>
                    <Typography className={classes.lgFont}>
                      Loading...
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <PropertyDisclaimer />
          </Grid>
        </Grid>
        <FeedbackModal
          open={showReferralModal}
          setOpen={() => {
            dispatch(
              configActions.setConfigValue({
                type: 'showReferralModal',
                value: false,
              })
            )
          }}
          onSubmit={handleSendFeedback}
        />
        <AddHomeCardModal
          open={addModalOpen}
          setOpen={setAddModalOpen}
          setShowDuplicatedModal={setShowDuplicatedModal}
        />
        <PurchaseModal
          groupedOrders={groupedOrders}
          // icon={<img src={hardwareConstruction} alt='construction' />}
          icon={getModalTitleIcon(purchaseModalTitle)}
          open={purchaseModalOpen}
          setOpen={setPurchaseModalOpen}
          title={purchaseModalTitle}
          titleColor={purchaseModalTitleColor}
        />
        <EstimateJobsModal
          // icon={<img src={hardwareConstruction} alt='construction' />}
          icon={getModalTitleIcon(purchaseModalTitle)}
          open={estimateJobOpen}
          setOpen={setEstimateJobOpen}
          title={purchaseModalTitle}
          titleColor={purchaseModalTitleColor}
          isEstimate={isEstimateModal}
        />
        <DuplicatedErrorModal
          open={showDuplicatedModal}
          setOpen={setShowDuplicatedModal}
        />
      </PrivateLoader>
    </DashboardContext.Provider>
  )
}

export default Dashboard
